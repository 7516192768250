#boxUsers button {
    padding: 0 15px;
    float: right;
    margin-top: 32px;
    margin-bottom: 0;
}

#boxUsers .form-group {
    margin-top: 32px;
    margin-bottom: 0;
    position: relative;
}

#boxUsers .form-control {
    padding-right: 40px;
}

#boxUsers .form-group svg {
    color: #009ca0;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 25px;
}

#tableUsers tbody td:nth-child(4n+1), #tableUsers tbody td:last-child {
    border-left: none !important;
}

.boxShowUsers {
    padding: 7px 17px;
}

.boxShowUsers span {
    font-size: 14px;
}

.boxShowUsers hr {
    border-top: 1px solid #FFF;
    margin: 10px 0 0 0;
}

div#alertUsers {
    background: #FFF;
    padding: 20px 7px 5px;
}

div#alertUsers p {
    margin-bottom: 15px;
}

form#newUser input {
    font-size: 14px;
}

.modalGreen .btnWhite {
    height: 40px;
}

.modalGreen span.input-group-text {
    color: #4c4c4c;
    padding: 5px;
}

@media screen and (max-width: 767px) {
    #boxUsers button, #boxUsers .form-group {
        float: initial;
        margin-top: 25px;
    }
}