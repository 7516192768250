.contractSuccess hr {
    border-top: 1px solid #4c4c4c;
    margin-top: 10px;
    margin-bottom: 15px;
}

.contractSuccess span {
    margin-bottom: 15px;
    display: table;
}

.boxDoubts {
    margin-top: 60px;
}

.boxDoubts h5 {
    margin-bottom: 20px;
}

.boxDoubts p {
    font-size: 18px;
}