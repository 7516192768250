.boxTechnical {
    margin-top: 9px;
}

.boxTechnical .title {
    font-weight: 600;
}

.boxTechnical .answer {
    font-weight: 300;
}

.boxTechnical button {
    margin: 20px 0 0 0;
}

.answerGray {
    color: #4c4c4c !important;
}

.boxTechnical ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 20px;
}

.boxTechnical ul li {
    margin-top: 10px;
}

.textCenter button {
    margin-top: 10px;
}

p#contactTechical {
    font-size: 27px;
}