.boxSummary {
    background: #c6c6c6;
    padding: 20px;
}

.summary h5 {
    margin-top: 20px;
}

.summary hr {
    border-top: 1px solid #4c4c4c;
    margin-top: 10px;
    margin-bottom: 0px;
}

.boxQuestions {
    margin-top: 9px;
}

.boxQuestions .title {
    margin-top: 4px;
}

p.answer {
    font-weight: bold;
}

p.answer span {
    font-weight: 400;
}

.boxGray h4 {
    font-weight: bold;
}

button.btnCNPJ {
    cursor: pointer;
    background: #4c4c4c;
    border: none;
    text-align: right;
    padding: 0 20px;
    height: 70px;
    border-radius: 50px 0 0 50px;
    font-size: 18px;
}

button.btnCNPJ span {
    cursor: pointer;
    color: #FFF;
    font-size: 13px;
}

button.btnCPF {
    cursor: pointer;
    background: #4c4c4c;
    border: none;
    height: 70px;
    border-radius: 0 50px 50px 0;
    text-align: left;
    padding: 0 20px;
    font-size: 18px;
}

button.btnCPF span {
    cursor: pointer;
    color: #FFF;
    font-size: 13px;
}

button.btnCNPJ.active, button.btnCPF.active {
    background: #009ca0;
}

div#boxGraySummary {
    margin: 60px 0;
}

p.fontSmall {
    font-size: 13px;
}

.boxSummary .title {
    margin-bottom: 10px;
}

.titleSummary {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    margin-bottom: 35px;
}

button#seeSummary {
    margin: 50px auto;
    display: table;
}

.checkKeyAccount {
    margin-bottom: 24px;
    margin-top: 16px;
}

@media screen and (max-width: 767px) {
    button.btnCNPJ, button.btnCPF {
        border-radius: 50px;
    }
}

.col-ie .form-check {
    margin-top: -6px;
    margin-bottom: 12px;
}