.btnsKey {
    background-color: #4c4c4c;
    border: 1px solid #4c4c4c;
    padding: 0;
    width: 100%;
    height: 50px;
    font-size: 15px;
    margin-top: 10px;
}

.btnsKey.active {
    background-color: #009ca0;
    border: 1px solid #009ca0;
}

div#boxResultKeyAccount {
    padding: 20px 15px 10px;
}

.answerGreenLight {
    background-color: #9cdcde;
    color: #4c4c4c !important;
    padding-left: 5px;
}

div#boxResultKeyAccount button {
    float: right;
    margin-top: 0;
}

#boxResultKeyAccount hr {
    margin: 8px 0;
}

#boxResultKeyAccount a.seeMoreDetails {
    color: #FFF;
}

@media screen and (max-width: 767px) {
    div#boxResultKeyAccount button {
        float: initial;
        margin: 20px auto;
        display: table;
    }
}