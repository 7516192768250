.priceRange {
    display: flex;
    justify-content: space-between;
}

.boxRangeActive {
    display: flex;
    justify-content: space-between;
}

.rangeActive {
    height: 35px;
    width: 48%;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    margin-top: 10px;
}

.input-range {
    margin: 20px 0 50px;
}

.input-range__slider {
    background: #c6c6c6 !important;
    border: 1px solid #c6c6c6 !important;
}

.input-range__track--active {
    background: #c6c6c6 !important;
}

.input-range__label {
    color: #4c4c4c !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
}

@media screen and (max-width: 700px) {
    .boxRange {
        padding: 0 35px;
    }
}

.input-range__track {
    transition: none !important;
}

.input-range__slider-container { transition: none !important; }

.input-range__label-container {
    display: none;
}

