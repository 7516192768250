div#divAbsolute {
    position: absolute;
    top: -2px;
    background: #00ACAF;
    width: 170px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 5px;
    color: #FFF;
}

.boxResultFilter {
    background: #f9f9f9;
    padding: 25px 20px 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #C1C1C1;
    box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.27);
}

.boxResultFilter p.title {
    font-weight: 800;
    color: #F69098;
    font-size: 18px;
    text-transform: uppercase;
}

.boxResultFilter ul {
    padding: 0;
    list-style: none;
    color: #4c4c4c;
}

.boxResultFilter ul li {
    margin-top: 20px;
}

.boxPaddingTop {
    padding-top: 25px;
}

.boxPaddingTop span {
    font-size: 30px;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
}

.boxPaddingTop span.withoutSubmissions {
    color: #ff0016;
    font-size: 22px;
    line-height: 20px;
}

.boxPaddingTop p {
    margin-top: -8px;
}

p#priceTotal {
    margin-top: 2px;
    font-size: 15px;
    margin-bottom: 10px;
    word-break: break-word;
}

.boxPaddingTop button {
    margin-top: 10px;
    font-weight: 700;
    width: 100%;
    padding: 0;
}
.boxResultFilter hr {
    margin-top: 0;
    border-top: 2px solid #00acaf;
    margin-bottom: 5px;
}

a.seeMoreDetails , a.seeMoreDetails:hover {
    display: table;
    margin: 0 auto;
    text-decoration: none;
}

.boxMoreDetails {
    background-color: #FFF;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    margin-top: 7px;
}

.boxMoreDetails p {
    font-size: 14px;
}

.icUp {
    transform: rotate(180deg);
}

.textGreen {
    color: #00ACAF;
}

ul#ulUsers {
    margin-top: 45px;
}

.textInvalidCode {
    color: #ca2432;
    position: absolute;
    left: 0;
    right: 0;
    top: 6px;
    font-weight: 700;
    font-size: 14px;
}

.textSMSSent {
    color: #007477;
    position: absolute;
    left: 0;
    right: 0;
    top: 6px;
    font-weight: 700;
    font-size: 14px;
}

.bgWhite {
    background: #FFF;
    margin-top: -25px;
    padding-top: 25px;
}

@media screen and (max-width: 767px) {
    .boxResultFilter {
        padding: 25px 15px 5px;
        margin-bottom: 20px;
    }
    .bgWhite {
        background: #FFF;
        margin-top: 0;
        padding-top: 10px;
        margin-bottom: 10px;
    }
}