.contract h5 {
    margin-top: 20px;
}

.contract hr {
    border-top: 1px solid #4c4c4c;
    margin-top: 10px;
    margin-bottom: 0px;
}

button#btnSignature {
    margin: 30px auto 20px;
    display: table;
    font-size: 19px;
}

.boxContract {
    text-align: center;
    margin: 10px 0;
}

.boxContract p {
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .boxContract {
        background: #009ca0;
        padding: 20px 7px;
        border-radius: 5px;
    }
    .boxContract p {
        color: #FFF;
    }
}