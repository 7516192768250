div.boxGraySummaryAccount {
    margin: 20px 0;
}

.boxGraySummaryAccount h4 {
    font-size: 22px;
}

.boxResultAccount {
    background: #009ca0;
    border-radius: 3px;
    padding: 5px 15px 25px;
    color: #FFF;
    margin-bottom: 25px;
}

.boxResultAccount .title, .boxResultAccount .answer {
    color: #FFF;
}

.boxResultAccount hr {
    border-top: 1px solid #FFF;
}

.boxResultAccount p {
    color: #FFF;
}

.boxResultAccount form p {
    font-size: 27px;
    font-weight: bold;
}

.boxConfiguration {
    margin-top: 15px;
}

.marginTop {
    margin-top: 35px;
}

.boxConfiguration p.titleSection {
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.boxConfiguration form {
    margin-top: 10px;
}

.boxConfiguration span {
    color: #FFF;
    font-size: 13px;
    display: table;
}

.spanWhite {
    color: #FFF;
}

.boxConfiguration .textCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
}

.btnWhite {
    background-color: #FFF;
    color: #009ca0;
    border: 1px solid #FFF;
    padding: 0 10px;
    height: 35px;
    margin-left: 10px;
}

.textCenter .btnWhite {
    margin-left: 20px;
}

div.modalGreen {
    background: #009ca0;
    padding: 35px 0;
}

div.modalGreen p, div.modalGreen span {
    color: #FFF;
    margin: 0 auto;
}

div.modalGreen .title {
    font-weight: 700;
    color: #FFF;
}

div.modalGreen form {
    margin-top: 10px;
}

div.modalGreen ul {
    margin-top: -5px;
}

div.modalGreen label.form-check-label {
    text-align: left;
    font-size: 13px;
    color: #FFF;
    margin-bottom: 15px;
}

#modalCodeKey p{
    color: #FFF;
}

button#activeAccount {
    margin: 35px auto 25px;
}

#modalCodeKey .btnModal {
    background-color: #c6c6c6;
    color: #4c4c4c;
}