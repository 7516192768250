@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,300&display=swap');

body {
    background: #f3f3f3;
}

div#root {
    background: #f3f3f3;
    height: 100vh;
}

h1, h2, h3, h4, h5, h6, p, a, button, span {
    font-family: 'Roboto', sans-serif;
    color: #4c4c4c;
}

.textCenter {
    text-align: center;
}

.title {
    font-family: 'Montserrat', sans-serif;
}

a {
    color: #4c4c4c;
    text-decoration: none;
}

a:hover {
    color: #4c4c4c;
    text-decoration: underline;
}

h3 {
    font-weight: 700;
    color: #4c4c4c;
}

.boxGray {
    background-color: #C6C6C6;
    padding: 30px 0;
}

p {
    margin: 0;
    font-size: 16px;
}

button {
    text-transform: inherit;
    color: #fff;
    background-color: #ca2432;
    border: 1px solid #ca2431;
    height: 40px;
    padding: 0 40px;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    box-shadow: none;
    outline: none;
}

.boxWhite {
    background-color: #FFF;
    padding: 30px 0;
    text-align: center;
}

.boxWhite h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 25px;
}

.boxGray Button {
    margin: 20px 0;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1110px;
    }
}

@media(min-width: 576px){
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 25px;
        padding-left: 25px;
    }
}

.form-control {
    height: 40px;
    outline: none;
}

.form-control:focus {
    box-shadow: none;
    border: 1px solid #009ca0;
}

@media screen and (max-width: 767px) {
    .form-control {
        margin-bottom: 10px;
    }
}

.modal {
    position: fixed;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1050;
    width: 90%;
    max-width: 510px;
    height: auto;
    max-height: 90vh;
    overflow: auto;
    outline: 0;
    background: #C6C6C6;
    margin: 0 auto;
    display: flex;
    border-radius: 5px;
    padding: 35px;
}

button.closeModal {
    position: absolute;
    top: 5px;
    right: 10px;
    background: transparent;
    border: transparent;
    color: #ca2432;
    height: auto;
    padding: 0;
    font-size: 22px;
    margin: 0 !important;
}

.modal .title {
    font-weight: 600;
}

.modal form {
    margin-top: 30px;
}

.modal span {
    font-size: 13px;
    display: table;
    margin-bottom: 6px;
}

.modal button {
    margin: 15px auto 0;
    display: table;
}

.modal button#btnDownloadModal {
    margin: 35px auto 0;
    display: table;
}

.modal ul {
    list-style: none;
    padding: 0;
    margin-top: 25px;
}

.modal ul li {
    margin: 10px 0;
}

button#btnDownload {
    margin-top: 40px;
}

.btnModal {
    padding: 9px 7px;
    height: auto;
    font-size: 11px;
    margin-top: 35px !important;
    background: #00acb0;
    border: 1px solid #00acb0;
}

.styles_react-code-input-container__tpiKG.undefined {
    margin: 40px auto 0;
}

.styles_react-code-input__CRulA > input:focus {
    outline: none;
    border: 1px solid #00acb0 !important;
    caret-color: #00acb0 !important;
}

@media screen and (max-width: 767px) {
    .modal {
        padding: 45px 5px;
    }
    #modalCode .form-control {
        margin-bottom: 10px;
        padding: 0;
        text-align: center;
    }
}