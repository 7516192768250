.btnCenter {
    margin: 25px auto 10px !important;
    display: table;
}

div#resultList {
    padding-top: 25px;
    margin-bottom: 0;
}

.boxTable {
    background: #9cdcde;
}

.boxTable button {
    padding: 0 10px;
    height: 30px;
    margin: 0;
}

.table {
    text-align: center;
    white-space: nowrap;
}

.table thead th, .table td, .table th {
    border: none;
    vertical-align: inherit;
}

tbody {
    background: #FFF;   
}

tbody td {
    border-left: 1px solid #CCC !important;
}

tbody td:nth-child(6n+1), tbody td:last-child {
    border-left: none !important;
}

@media screen and (max-width: 768px) {
    .table thead th {
        font-size: 12px;
    }
    .table td {
        font-size: 14px;
    }
    .boxTable button {
        font-size: 12px;
    }
}