@import '../../styles/colors';

header {
    padding: 20px 0;
}

header img {
    width: 100%;
    max-width: 116px;
}

.header-col-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9;
}

@media(max-width: 575px){
    header img {
        margin: 0 auto;
        display: flex;
    }
    .search-bar {
        width: 90%;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}