.boxFilters {
    margin-bottom: 15px;
    border: 1px solid #C1C1C1;
}

.boxBody {
    padding: 25px 20px 30px;
    background: #FFF;
}

.boxFilters p {
    font-weight: 700;
    color: #4c4c4c;
    margin-bottom: 5px;
}

.boxFilters button, .boxFilters button:hover, .boxFilters button:active {
    text-transform: inherit;
    color: #fff;
    background-color: #ca2432 !important;
    border: 1px solid #ca2431 !important;
    height: 40px;
    width: 100%;
    padding: 0;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    box-shadow: none !important;
    outline: none;
    margin-bottom: 10px;
}

input.form-check-input {
    margin-top: 6px;
}

.form-check-label {
    margin-bottom: 0;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
}

.boxFilters span {
    display: block;
    font-size: 14px;
    font-style: italic;
    margin-top: 16px;
    font-family: 'Roboto', sans-serif;
}

div#boxFiltersBg span {
    color: #4c4c4c;
}

p.marginTop {
    margin-top: 15px;
}

.pNoMargin {
    line-height: 20px;
    margin-bottom: 0 !important;
}

.rowBorderBottom {
    align-items: center;
    border-bottom: 2px solid #4c4c4c;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

div#boxGrayInFilters {
    margin: 50px 0 28px;
}

form#formOrder {
    display: none;
}

.orderPlans .col-sm-4, .orderPlans .col-sm-8 {
    padding-left: 0px;
}

.labelOrder {
    float: right;
    margin-top: 6px;
}

div#boxFiltersBg {
    background: #6fc5c6;
    color: #6fc5c6;
    padding: 20px 35px 20px 20px;
}

div#boxFiltersBg .title {
    color: #4c4c4c;
}

.boxMailing {
    padding-bottom: 15px;
    margin-top: 8px;
    padding-left: 40px;
    border: 2px solid #FFF;
}

.boxMailing.col-sm-7 {
    border-right: none;
}

.boxMailing span {
    font-style: initial;
    margin-top: 0;
    margin-bottom: 10px;
}

.positionMore {
    background: #6fc5c6;
    position: absolute;
    top: 104px;
    left: -11px;
    p {
        font-size: 32px;
        color: #FFF;
        font-weight: 400;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 767px) {
    .labelOrder {
        float: left;
    }
    form.orderPlans, form#formOrder {
        display: block;
    }
    form#formOrder {
        margin-top: 30px;
    }
    .orderPlans .col-sm-4, .orderPlans .col-sm-8 {
        padding-left: 15px;
    }
}

div#overlay {
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
}

.filterMobile {
    margin-bottom: 25px;
    z-index: 9999;
    display: block;
}

div#btnFilterMobile {
    position: relative;
    display: flex;
    background: #00acb0;
    color: #FFF;
    padding: 15px;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
}

div#btnFilterMobile.btnHideDesk {
    display: none;
}

span.showResults {
    font-size: 14px;
    display: none;
    margin-top: 25px;
    text-align: right;
}

.menuShowResult {
    position: relative;
    right: 0;
    left: 0;
    bottom: -15px;
    background: #00acb0;
    padding: 8px 15px;
    display: none;
}

.menuShowResult span {
    color: #FFF;
}

.menuShowResult button {
    width: 100%;
    padding: 0;
    margin-top: 5px;
}

@media screen and (max-width: 767px) {
    .filterMobile, .showResults {
        display: block;
    }
    div#btnFilterMobile.btnHideDesk {
        display: flex;
    }
    div#boxGrayInFilters {
        display: none;
    }
    .boxPaddingTop {
        padding-top: 15px;
    }
    .boxPaddingTop button {
        margin-top: 10px;
        margin: 20px auto;
        display: table;
    }
    p.marginTop {
        margin-top: 0;
    }
    .boxMailing span {
        color: #4c4c4c;
        margin-top: 10px;
    }
    .boxFilters {
        margin: 0 15px 15px;
    }
}

form.formPrice {
    margin-top: 4px;
    margin-bottom: -6px;
}

.boxTitle svg {
    display: none;
}

.boxBody.show, .filterMenu.show {
    display: block;
}

.boxTitle {
    background: #70C5C6;
    padding: 15px;
    color: #FFF;
    font-family: 'Roboto';
    font-size: 17px;
    border-radius: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        margin-bottom: 0;
        color: #4c4c4c;
        font-weight: 400;
    }
    svg {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .boxTitle {
        svg {
            display: block;
        }
        p {
            color: #FFF;
        }
    }
    .boxBody {
        background: #FFF;
        padding: 25px 20px 30px;
    }
    .menuFilter {
        background: #e6e6e6;
        padding: 15px 0;
        position: absolute;
        z-index: 9;
        right: 15px;
        left: 15px;
    }
    .boxBody.hide, .menuFilter.hide {
        display: none;
    }
    .menuShowResult {
        display: block;
    }
    .positionMore {
        display: none;
    }
    div#boxFiltersBg .title {
        color: #FFF;
    }
}

#boxFiltersBg .boxTitle {
    display: none;
}

#boxFiltersBg .boxBody {
    padding: 0;
    background: #6fc5c6;
}

#boxFiltersBg .boxBody label {
    color: #4c4c4c;
}

.position {
    position: absolute;
    top: 44%;
    right: -25px;
    text-align: center;
    background: #6fc5c6;
    z-index: 8;
    height: 37px;
    width: 50px;
    p {
        font-size: 26px;
        color: #FFF;
    }
}

p.alignMobile {
    text-align: center;
    color: #FFF;
    margin-bottom: 20px;
}

p#alignHideDesk {
    font-weight: 400;
    display: none;
}

p#moreMobile, .positionMobile {
    display: none;
}

div#boxMailingUnlimited {
    margin-top: -100px;
}

@media screen and (max-width: 767px) {
    .position, div#mailingBorder {
        display: none;
    }
    div#boxFiltersBg {
        padding: 20px 15px 0 15px;
    }
    #boxFiltersBg .boxTitle {
        display: flex;
    }
    #boxFiltersBg .title.marginTop {
        display: none;
    }
    #boxFiltersBg .boxBody {
        padding: 0px 20px 15px;
    }
    .boxMailing {
        background: transparent;
        padding-bottom: 15px;
        margin-top: 5px;
        padding-left: 14px;
        border: none;
    }
    p#alignHideDesk {
        display: block;
    }
    .boxBgMobile {
        background: transparent;
        padding-top: 15px;
    }
    .positionMobile {
        display: flex;
        text-align: center;
        background: #ca2432;
        z-index: 99;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 20px;
        font-weight: bold;
        border-radius: 100%;
        position: absolute;
        top: -19px;
        right: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    p#moreMobile {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        margin-top: -15px;
        display: block;
    }
    div#boxMailingUnlimited {
        margin-top: 0;
    }
    .bgMobileW {
        background: #006d6f;
        margin-top: 35px;
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
    }    
}

.boxTooltip {
    display: flex;
}

.formTooltip {    
    margin-right: 5px;
}

span.input-group-text {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: 400;
}

p.priceChangeAlert {
    position: absolute;
    font-size: 12px;
    bottom: 18px;
    font-weight: 400;
}