.boxBtnsKey {
    margin-top: 50px;
}

.boxBtnsKey button {
    background-color: #009ca0;
    border: 1px solid #009ca0;
    width: 100%;
    height: 70px;
    font-size: 19px;
}

.boxBtnsKey p {
    font-size: 14px;
    text-align: center;
    padding: 0 5px;
    margin-top: 10px;
    margin-bottom: 40px;
}