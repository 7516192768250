#boxMoreDetailsContract {
    background-color: #a3ecef;
}

.boxResultContract {
    background-color: #68c6c8;
    padding: 10px 15px;
}

.boxResultContract p.title, .boxResultContract p.answer {
    color: #4c4c4c;
    font-size: 16px;
}

.boxResultContract p.answer {
    margin-bottom: 5px;
}